function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function () {
    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
        if ($('#container_form form').length) {
            $('#contactRgpd').rules('add', {
                validateCgv: true
            });
            $('#f_recaptcha').rules('add', {
                validateRecaptcha: true
            });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#contactRgpd').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });



    $('.marker-link').on('click', function () {
        google.maps.event.trigger(markers[$(this).data('markerid')], 'click');
    });

    // HEADER TOGGLE MENU
    $('.toggleMenu').click(function() {
        $('html').toggleClass('active');
        $(this).toggleClass('open');
        
        $('.mainMenu-items').toggleClass('open');
    });


    $('.mainMenuLabel-sub').click(function() {
        var heightLabel = $('.label').outerHeight(true);
        // console.log(heightLabel);

        var heightSub = $('.containerSub').outerHeight(true);
        // console.log(heightSub);
        $(this).find('.label').toggleClass('open');
        $('.subMenu').toggleClass('open');

        if($('.subMenu').hasClass('open')) {
            $('.subMenu').css('height', heightSub);
        } else {
            $('.subMenu').css('height', '0');
        }

    });

    // HOME SLIDER
    $('.owl-home').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        items: 1,
        autoplay: true,
        autoplayTimeout: 6000,
    });

    $('.owl-home .owl-dots').wrap('<div class="container containerDots"></div>');

    // BLOCK SLIDER
    $('.owl-blockSlider').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        items: 1,
    });

    // HÉBERGEMENT SINGLE SLIDER    
    $('.owl-imgs').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        items: 1,
    });

    $('.owl-crossContent').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        items: 4,
        margin: 15,
        responsiveClass: true,
        responsive : {
            0 : {
                items: 2,
                dots: true,
            },
            768 : {
                items: 3,
                dots: true,
            },
            991 : {
                items: 4,
                dots: true,
                
            }
        }
    });

    $('.owl-crossContentDestroyed').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        items: 4,
        margin: 15,
        responsiveClass: true,
        responsive : {
            0 : {
                items: 2,
                loop: true,
                // freeDrag: true,
                mouseDrag: true,
                touchDrag: true,
                dots: true,
            },
            768 : {
                items: 3,
                loop: true,
                // freeDrag: true,
                mouseDrag: true,
                touchDrag: true,
                dots: true,
            },
            991 : {
                items: 4,  
                // freeDrag: false,
                mouseDrag: false,
                touchDrag: false,              
            }
        }
    });

    // // BLOCK MAP
    // $($('map').prev('div[role="button"]')).click(function() {
    //     // var attr = $(this).attr('tabindex');
    //     console.log('salut');
    // });
});
